import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import BlocksRenderer from "@components/BlocksRenderer";
import FadeInOnView from "@components/FadeInOnView";
import Footer from "@components/Footer";
import Grid from "@components/Grid";
import useUIColorCheck from "@utils/useUIColorCheck";
import getPostData from "@utils/getPostData";

import "./style.css";

const Privacy = ({ data, transitionStatus }) => {
  useUIColorCheck([transitionStatus === "entered"]);

  const privacy = getPostData("page", { data });

  const pageTitle = data?.wpgraphql?.page.metaTagsAcf.pageTitle
    ? data?.wpgraphql?.page.metaTagsAcf.pageTitle +
      " | " +
      data?.site?.siteMetadata.title
    : data?.wpgraphql?.page.title;

  const pageDescription = data?.wpgraphql?.page?.metaTagsAcf?.pageDescription;
  const pageImage = data?.wpgraphql?.page?.metaTagsAcf?.pageImage?.mediaItemUrl;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {pageImage && <meta property="og:image" content={pageImage} />}
      </Helmet>

      <div className="page privacy-page header-color--light">
        <FadeInOnView>
          <Grid.Container fluid narrowMargins>
            <Grid.Row>
              <Grid.Cell
                offset={2}
                mobileOffset={0}
                columns={8}
                mobileColumns={4}
              >
                <h1 className="privacy-page__title">{pageTitle}</h1>

                <BlocksRenderer
                  blocks={privacy.blocks}
                  entered={transitionStatus === "entered"}
                  includeGrids={false}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Container>
        </FadeInOnView>
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($wpId: ID!) {
    site {
      siteMetadata {
        title
      }
    }
    wpgraphql {
      page: page(id: $wpId, idType: DATABASE_ID) {
        uri
        title
        status
        slug
        content(format: RENDERED)

        metaTagsAcf {
          pageTitle
          pageDescription
          pageImage {
            mediaItemUrl
          }
        }
        blocks {
          ...Roll_Block
        }
      }
    }
  }
`;
export default Privacy;
